<template>
  <div>
    <div ref="elementHeader" class="header-data" v-if="isCategory"> 
    <div class="top-title">
        <div class="left">{{ $t('home.contect_us') }}</div>
        <div class="center">{{ $t('home.call_us') }}: +44 1908 665 883</div>
        <div class="right">
            <div>
                <span @click="clickZh" :class="localeLable == '1'?'localeLableFont':''" class="language">中文</span> 
                <span class="division">|</span> 
                <span @click="clickEn" :class="localeLable == '2'?'localeLableFont':''" class="language">Eng</span>
            </div>
            <div class="login" @click="getSignOut" v-if="userData.nickName"><img src="@/assets/images/Icon.png"/> {{$t('login.sign_out')}}</div>
            <div class="login" @click="getLogin" v-if="!userData.nickName">{{$t('login.login')}}</div>
        </div>
    </div>
    <div class="top-info">
        <div class="logo" @click="jumpHome">
            <img src="@/assets/images/suppally.png"/>
        </div>
        <div class="classification" @click="showFun" :style="{color:classShow ? '#FF8500' : '#000000'}">
            <img v-show="classShow" src="@/assets/images/classification.png"/>
            <img v-show="!classShow" src="@/assets/images/classification2.png"/>
            <span>{{ $t('home.categories') }}</span>
            <span class="el-icons" :class="classShow ? 'el-icon-arrow-down':'el-icon-arrow-up'"></span>
        </div>
        <div class="top-menu">
            <div class="menu-list"> 
                <div :class="checkType==0?'check':''" @click="getPage('/')">{{ $t('common.home_url1') }}</div>
                <div :class="checkType==1?'check':''" @click="getPage('/order/index')">{{ $t('common.home_url2') }}</div>
                <div :class="checkType==2?'check':''" @click="jumpPersonal">{{ $t('common.home_url3') }}</div>
                <!-- <div :class="checkType==3?'check':''" @click="jumpPrizemall">{{ $t('common.home_url4') }}</div> -->
            </div>
            <div class="menu-input"> 
                <el-input :placeholder="$t('common.searchFont')" v-model="inputFont" @keyup.enter.native="getSearch">
                    <i slot="suffix" class="el-input__icon el-icon-search" @click="getSearch()"></i>
                </el-input>
                <div class="menu-wallet" @click="jumpIntegral">
                    <img src="@/assets/images/wallet2.png"/>
                    <span>£{{ parseFloat($store.state.wallet.wallet).toFixed(2) }}</span>
                </div>
            </div>
        </div>
        <div class="cart" @click="getCart">
            <el-badge v-if="$store.state.cart.allGoods != 0" :value="$store.state.cart.allGoods" class="item">
                <img src="@/assets/images/cart2.png"/>
            </el-badge>
            <img v-else src="@/assets/images/cart2.png"/>
            £{{ $store.state.cart.goodsAmount }}
        </div>
    </div>
    <div class="bread-bor" v-if="crumbs"> 
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: item.path }" v-for="(item,index) in crumbsList" :key="index">{{ lang == 'zh' ? item.name : item.nameEn }}</el-breadcrumb-item>
        </el-breadcrumb>
    </div>
    <!-- 一级分类 -->
    <div class="wrap">
        <!-- 标签导航 -->
        <div style="padding:0;height:0px;" v-if="tabList.length > 12" class="searchBox navBox navH">
            <div class="contrlBoxLeft flexcenter">
                <i class="el-icon-arrow-left" :style="tabChose == tabList[0].id?'color:#CCCCCC':'color:#080808'" @click="tabLeft"></i>
            </div>
            <!-- width:1120px; -->
            <div style="width:1350px;margin: 0 auto;">
                <div class="good_city" :style="'transform: translateX(' + tabIndex + 'px);'">
                    <div class="tabBox" :id="'tab' + item.id" v-for="(item,index) in tabList" :key='index' :class="tabNum == item.id?'tabChose':''" @click="choseTab(item)">
                        <img :src="item.image"/>
                        <div class="class-name" :title="lang == 'zh' ? item.label : item.labelEn" >{{lang == 'zh' ? item.label : item.labelEn}}</div>
                        <div class="class-check" v-if="tabNum == item.id"></div>
                    </div>
                </div>
            </div>
            <div class="contrlBox flexcenter">
                <i class="el-icon-arrow-right" :style="parseInt(this.tabChose) + 11 >= tabList[tabList.length-1].id?'color:#CCCCCC':'color:#080808'" @click="tabRight"></i>
            </div>
        </div>
        <div class="navBox2 navH" style="height:0px;padding:0px;" v-else>
            <div v-for="(item,index) in tabList" :key='index'  class="tabBox" @click="choseTab(item)">
                <img :src="item.image"/>
                <div class="class-name" :class="tabNum == item.id?'tabChose':''" :title="lang == 'zh' ? item.label : item.labelEn" >{{lang == 'zh' ? item.label : item.labelEn}}</div>
                <div class="class-check" v-if="tabNum == item.id"></div>
            </div>
        </div>
    </div>
    <!-- 一级分类结束 -->
    </div>
    <div style="transition: all 0.2s;" :style="'height:'+heightNum+'px'"></div>
  </div>
</template>

<script>
import { categoryList } from '@/api/category';
import { mapState } from 'vuex';
export default {
    name:'topIndex',
    data() {
        return {
            isCategory:false,
            userData:{},
            lang:localStorage.getItem('lang') || 'en',
            tabChose:0,//选择的tab
            tabIndex:0,//tab需要移动的宽
            classShow:false,
            box:null,
            localeLable:localStorage.getItem('lang') == 'en' ? "2":'1' || "2",
            tabList:[],
            inputFont:'',
            headHeight:100,//头部固定高度
            heightNum:100,
            tabNum:-1,//自定义选择得tab
            tabItem:null,
            breadcrumbList:[{
                name:"首页",
                nameEn:"home",
                path: '/'
            }],//面包屑列表
        }
    },
    computed:mapState(['checkList']),
    watch: {
      "checkList.tab_index": {
          handler(newValue, oldValue) {
            this.tabNum = newValue.tabNum;
          },
          immediate: true,
          deep: true
      }
    },
    props: {
        crumbs:{
            default:true,
            type:Boolean
        },
        checkType:{
            type: Number, 
            default: 0
        },
        crumbsList:{
            type: Array,
            required: true,
        }
    },
    created(){
        if(JSON.parse(sessionStorage.getItem('userData'))){
            this.userData = JSON.parse(sessionStorage.getItem('userData'));
        }
        if(sessionStorage.getItem("secondaryClass")){
            let secondaryClass = JSON.parse(sessionStorage.getItem("secondaryClass"));
            this.tabNum = secondaryClass.id;
        }
        if(sessionStorage.getItem("inputFont")){
            this.inputFont = sessionStorage.getItem("inputFont");
        }
        this.getCategoryList();
    },
    methods:{
        getCategoryList(){
            this.isCategory = false;
            categoryList().then(data => {
                this.tabList = data.data.list;
                this.tabList.forEach((element,index) => {
                    element.id = index;
                });
                this.$store.commit('SET_CATEGORIES_LIST', this.tabList);
                this.isCategory = true;
                this.$nextTick(()=>{
                    setTimeout(()=>{
                        this.headHeight = this.$refs.elementHeader.offsetHeight;
                        if(this.$route.path == '/'){
                            this.headHeightFun(0);
                            this.showTypeFun(true);
                        }else if(this.$route.path == '/home/list'){
                            this.headHeightFun(0);
                            this.showTypeFun(true);
                        }else{
                            this.headHeightFun(0);
                        }
                        
                    },500);
                });
            }).catch(() => {})
        },
        choseTab(item) { //点击tab 查询二级分类
            sessionStorage.setItem("secondaryClass",JSON.stringify(item));
            this.tabItem = item;
            this.tabNum = item.id;
            if(this.$route.name == 'list'){
                sessionStorage.setItem("firstGetList",'2');
                this.$store.commit('HOME_LIST', {item:item});
            }else{
                sessionStorage.setItem("firstGetList",'1');
                this.$router.push({path:'/home/list',query:{item:item}});
            }
        },
        headHeightFun(num){
            this.heightNum = this.headHeight - num;
            this.$store.commit('SET_HEIGHT', this.heightNum);
        },
        getLogin(){
            this.$router.push('/login')
        },
        getSignOut(){
            var that = this;
            that.$confirm(that.$t('common.is_out'), that.$t('common.tip'), {
                confirmButtonText: that.$t('common.confirm'),
                cancelButtonText: that.$t('common.cancel'),
                type: 'warning'
            }).then(() => {
                that.$store.dispatch('LogOut').then(() => {
                    this.$router.go(0);
                }).catch(error => {
                    // 错误分为 status-请求错误 和 code-账号密码错误
                    that.$message.error(error);
                });

            }).catch(() => {});
        },
        getCart() {
            this.$router.push('/cart/index')
        },
        jumpIntegral() {
            this.$router.push('/wallet/index')
        },
        jumpPrizemall() {
            this.$router.push('/prize/index')
        },
        jumpHome(){
            this.$router.push('/')
        },
        clickZh() {
            this.$i18n.locale = 'zh';
            this.localeLable = '1';
            localStorage.setItem('lang','zh')
        },
        clickEn() {
            this.$i18n.locale = 'en';
            this.localeLable = '2';
            localStorage.setItem('lang','en')
        },
        jumpPersonal() {
            this.$router.push('/personal/index')
        },
        getPage(page){
            this.$router.push(page)
        },
        getSearch(){
            if(this.inputFont == ''){
                this.$message({
                    message: this.$t('common.searchTip'),
                    type: 'warning'
                });
                return;
            }
            sessionStorage.setItem("inputFont",this.inputFont);
            if(this.$route.name == 'search'){
                this.$emit('search',this.inputFont);
            }else{
                this.$router.push({path:'/search/index',query:{goodsName:this.inputFont}});
            }
        },
        tabLeft(){//左箭头切换
            if (this.tabChose != 0) {//判断是否是初始位置
                var tabid = '';
                this.tabList.forEach((item, index) => {
                    if (item.id == this.tabChose) {
                    tabid = 'tab' + this.tabList[index - 1].id
                    }
                })
                this.tabChose = tabid.replace(/tab/g,'')
                this.tabIndex += (document.getElementById(tabid).clientWidth + 38)
                //内容往左边移动上一个div的宽
            }
        },
        tabRight(){//右箭头切换
            if(parseInt(this.tabChose) + 11 >= this.tabList[this.tabList.length-1].id){
                return;
            }
            var tabid = '',
                tabidnow = 'tab' + this.tabChose;
            this.tabList.forEach((item,index)=>{
                if(item.id == this.tabChose){
                    tabid = 'tab' + this.tabList[index+1].id
                }
            })
            this.tabChose = tabid.replace(/tab/g,'');
            this.tabIndex -= (document.getElementById(tabidnow).clientWidth + 38)
        },
        showFun(){
            this.classShow = !this.classShow;
            this.controlFun();
        },
        showTypeFun(status){
            this.classShow = status;
            this.controlFun();
        },
        controlFun(){
            this.box = document.getElementsByClassName("navH")[0];
            if(this.classShow){
                this.box.classList.add('navH_show');
                this.box.classList.remove('navH_hidden');
                this.headHeightFun(-150);
            }else{
                this.box.classList.remove('navH_show');
                this.box.classList.add('navH_hidden');
                this.headHeightFun(0);
            }
        }
    }
}
</script>

<style>
.bread-bor{
    padding: 30px 0;
    padding-left: 3%;
    border-bottom: 1px solid #DEDEDE;
    background-color: #ffffff;
    position: relative;
    z-index: 1;
}
.language{
    cursor: pointer;
}
.localeLableFont{
    font-weight: bold;
}
.el-badge__content.is-fixed{
    top: -10px;
    right: 20px;
    background-color: #ffffff !important;
    color: #FF8500 !important;
}
.header-data{
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    min-width: 1200px;
    z-index: 10;
    background: #ffffff;
}
.top-title{
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #DEDEDE;
    height: 50px;
    line-height: 50px;
    padding: 0 20px;
    z-index: 9;
    position: relative;
    background-color: #ffffff;
}
.top-title .left{
    font-size: 14px;
    font-family: Gotham-Bold;
    font-weight: bold;
    color: #525457;
}
.top-title .center{
    font-size: 17px;
    font-family: Gotham-Bold;
    font-weight: bold;
    color: #FF8500;
}
.top-title .right{
    display: flex;
    font-size: 14px;
    font-family: Gotham-Book;
    font-weight: normal;
    color: #515356;
}
.top-title .right .division{
    display: inline-block;
    margin: 0 6px;
}
.top-title .right .login{
    margin-left: 23px;
    display: flex;
    align-items: center;
    font-family: Gotham-Bold;
    cursor: pointer;
}
.top-title .right .login img{
    width: 18px;
    height: 18px;
    margin-right: 6px;
}
.top-info{
    display: flex;
    height: 75px;
    line-height: 75px;
    border-bottom: 1px solid #DEDEDE;
    z-index: 9;
    position: relative;
    background-color: #ffffff;
}
.top-info .logo{
    border-right: 1px solid #DEDEDE;
    width: 170px;
}
.top-info .logo img{
    margin-top: 20px;
    cursor: pointer;
}
.top-info .classification{
    border-right: 1px solid #DEDEDE;
    width: 230px;
    font-size: 15px;
    font-family: Gotham-Bold;
    font-weight: normal;
    color: #FF8500;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.top-info .classification img{
    width: 12px;
    height: 12px;
    /* margin-left: 20px; */
    margin-right: 10px;
}
.top-info .classification .el-icons{
    margin-left: 10px;
    font-size: 18px;
    font-weight: bold;
}
.top-info .top-menu{
    width: calc(100% - 545px);
    display: flex;
    justify-content: space-between;
}
.top-info .top-menu .menu-list{
    display: flex;
    padding: 0 10px;
    font-size: 15px;
    font-weight: normal;
    min-width: 432px;
}
.top-info .top-menu .menu-list .check{
    color: #F68908 !important;
}
.top-info .top-menu .menu-list div{
    margin: 0 10px;
    cursor: pointer;
}
.menu-input{
    display: flex;
    align-items: center;
    width: 42%;
}
.menu-input .el-input{
    /* width: 318px; */
    margin-right: 45px;
}
.menu-input .el-input__inner{
    color: #A4A4A4;
    border: 1px solid #A5A5A5;
    height: 45px;
    line-height: 45px;
}
.el-input__icon{
    cursor: pointer;
    height: 45px;
    line-height: 45px;
    margin: 15px 0;
    color: #A5A5A5;
    font-weight: bold;
    font-size: 18px;
}
.menu-wallet{
    display: flex;
    align-items: center;
    cursor: pointer;
}
.menu-wallet span{
    display: inline-block;
    margin-left: 7px;
    margin-right: 22px;
}
.top-info .cart{
    width: 145px;
    background: #FF8500;
    font-family: Gotham-Bold;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    cursor: pointer;
}
.top-info .cart img{
    width: 18px;
    height: 18px;
    margin-right: 10px;
    display: block;
}
/* 一级分类 */
.wrap{
    z-index: 8;
}
.class-check{
    font-size: 32px;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: #FF8500;
    text-align: center;
    margin: 0 auto;
    margin-top: 4px;
}
.class-name{
    overflow:hidden;
    text-overflow:ellipsis;
    display:-webkit-box;
    -webkit-box-orient:vertical;
    -webkit-line-clamp:2;
    font-size: 14px;
    font-weight: 400;
    /* height: 40px; */
    height: 37px;
}
.tabBox{
    width: 74px;
    border-radius: 4px;
    color: #333333;
    font-size: 16px;
    /* margin: 0 25px; */
    margin: 0 19px;
    float: left;
    cursor: pointer;
}
.tabBox img{
    width: 60px;
    height: 60px;
    margin-bottom: 10px;
    border-radius: 14px;
}
.tabBox:hover{
    color: #FF8500;
    transition: all 0.2s;
}
.tabBox:hover img{
    opacity: 0.7;
    transition: all 0.2s;
}
.tabChose{
    color: #FF8500;
}
/* .tabChose img{
    opacity: 0.7;
} */
.good_city{
    width: 9999px;
    height: 40px;
    transition: all .5s;
}
.contrlBox{
    position: absolute;
    right: -22px;
    bottom: 0;
    z-index: 2;
    width: 80px;
    height: 100%;
    background: #ffffff;
}
.contrlBoxLeft{
    position: absolute;
    left: -22px;
    bottom: 0;
    z-index: 2;
    width: 80px;
    height: 100%;
    background: #ffffff;
}
.contrlBox i{
    font-size: 24px;
    font-weight: bold;
    flex-wrap: bold;
    margin-top: 54px;
}
.contrlBox i:hover{
    cursor: pointer;
}
.contrlBoxLeft i{
    font-size: 24px;
    font-weight: bold;
    flex-wrap: bold;
    margin-top: 54px;
}
.contrlBoxLeft i:hover{
    cursor: pointer;
}
.navBox{
    width: 1430px;
    padding: 15px 20px;
    margin: 0px auto;
    background: #ffffff;
    position: relative;
    overflow: hidden;
    display:flex;
    justify-content:center;
    align-items:center;
}
.navBox2{
    width: 100%;
    padding: 15px 20px;
    margin: 0 auto;
    background: #ffffff;
    position: relative;
    overflow: hidden;
    display:flex;
    justify-content:center;
}
.navH_show{
    height: 120px;
    padding: 20px 0 10px 0 !important;
    animation: moveShow 0.3s linear forwards;
}
.navH_hidden{
    height: 0px;
    padding: 0px !important;
    animation: moveHideen 0.3s linear forwards;
}
.defaultNav{
    height: 0px;
    padding: 0px;
}
.navH{
    
}
@keyframes moveHideen
  {
      from {
        transform: translateY(0px);
        height: 120px;
        }
      to {
        transform: translateY(-160px);
        height: 0px;
      }
  }
  @keyframes moveShow
  {
      from {
        transform: translateY(-160px);
        height: 0px;
        }
      to {
        transform: translateY(0px);
        height: 120px;
      }
  }
  
  @-webkit-keyframes moveHideen
  {
      from {transform: translateY(0px);height: 120px;}
      to {transform: translateY(-160px);height: 0px;}
  }
  @-webkit-keyframes moveShow
  {
      from {transform: translateY(-160px);height: 0px;}
      to {transform: translateY(0px);height: 120px;}
  }
/* 一级分类结束 */
</style>