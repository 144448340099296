module.exports = {
    router:{
        home:'首页' 
    },
    common:{
        status:"狀態",
        confirm:"確認",
        cancel:'取消',
        name:'名稱',
        detail:"詳情",
        deleteTip:"確認刪除該订单嗎？",
        tip:"提示",
        tips_title:"提示",
        success:"操作成功",
        error:"操作失敗",
        close:'關閉',
        delete:"刪除",
        prize_mall:"獎品商城",
        Submit:"提交",
        out_stock:"缺貨",
        in_stock:"現貨",
        stock:"庫存",
        sale:"特惠",
        login_set:"登錄以查看更多優惠",
        login:"登錄",
        home:"去首頁",
        home_url1:'首頁',
        home_url2:'我的訂單',
        home_url3:'我的賬戶',
        home_url4:'獎品購物中心',
        searchFont:'搜索您要查找的内容',
        searchTip:'請輸入您要查找的内容',
        categories:'類別',
        link:'用戶鏈接',
        content:'聯係我們',
        download:'下載 App',
        is_out:'確認退出登錄？'
    },
    login:{
        success:'修改成功',
        tip:'单击按钮重新登录',
        phone:'手機號碼',
        password:'密碼',
        login:'登錄',
        sign_out:'退出'
    },
    home:{
        add:'添加購物車',
        stock:'件剩餘庫存!',
        end:'結束',
        hot_sale:'熱銷商品',
        offer:'爲您提供',
        view_all:'查看全部',
        contect_us:'聯係我們',
        call_us:'致電我們',
        categories:'所有類別',
        notice:'通知 !',
        search_none:'沒有搜索到物品',
        wish_list:'心愿單',
        my_Order:'我的訂單',
        my_Account:'我的賬戶',
        about_Us:'聯係我們',
        reture_Policy:'退貨條款',
        terms_of_Service:'服務條款',
        Email:'電子郵箱',
        Tel:'電話',
        no_goods:'暫時無貨'
    },
    personal:{
        personal:'個人中心',
        favourites:"最愛",
        changepass:"修改密碼",
        more:'更多',
        small_more:'更多',
        wallet_balance:'積分餘額',
        redeem:"兌換",
        orderNum:'訂單號',
        orderDate:'下單日期',
        shippingDate:'計劃發貨日期',
        operation:'操作',
        myOrder:'我的訂單',
        oldpass:'舊密碼',
        newpass:'新密碼',
        confirmpass:'確認密碼',
        newpass_tip:'請輸入您的新密碼',
        oldpass_tip:"請輸入您的舊密碼",
        confirmpss_tip:"請輸入您的確認密碼",
        pas_tip:"請輸入密碼",
        num_tip:"请输入賬號",
        pas_success:"修改密碼成功",
        favorite_canceled:"已取消最愛",
        view_details:"查看詳細"
    },
    wallet :{
        Variation:'積分',
        ORDER_REFUND:'訂單退款',
        THE_PURCHASE_OF_GOODS:'購買商品',
        ACTIVITY_BOUNDS:'活動獎金',
        ORDER_BONUS:'獲得的積分',
        CUT_BONUS:'扣减奖金',
        REDEEM_PRIZE:'兌換商品',
        order:'訂單',
        history:'歷史記錄',
        empty_tip:'還沒有詳細記錄',
        History:'歷史'
    },
    prize:{
        wallet:'錢包',
        balance:'餘額',
        redeemSuccess:'兌換成功',
        redeemWarning:"兌換失敗",
        des:" 獎品一經兌換不能更改、刪除和退回,公司保留最終解釋權",
        exchange_clause:"兌換條款 ",
        tc1:'所有奖品视乎供应情况而定，Pacific Foods有权再没有通知的情况下取消、替代、更改奖品。',
        tc2:"Pacific Foods拥有最终决定权并不得进行任何交涉和争辩。"
    },
    order:{
        status0:'待支付',
        status1:"支付成功",
        status2:"支付失败",
        status3:'客户取消',
        status4:"已失效",
        status5:"已退款",
        status6:'支付中',
        status7:"已刪除",
        ADD_ON:"補充商品",
        ADD_ON2:"補充訂單",
        review:"評價",
        cancel:"取消訂單",
        lottery:"抽獎",
        deleted:"刪除訂單",
        paymenting:"訂單支付中...",
        review_order:"訂單評價",
        review_tip:'請對您的銷售人員進行評分',
        rate:'評分',
        remark:"留言",
        order_detail:"訂單詳情",
        free_prize:"平臺贈送獎勵",
        goods_total:'商品總數',
        top_up:"錢包獎金",
        master_order:"主訂單",
        supplementary_order:"子訂單",
        commodity_information:"訂單詳情",
        batch_number:'批號',
        star_tip:"評分不能小於1星",
        suborder_number:"子訂單號",
        creat_time:"下單時間",
        order_title1:'等待支付',
        order_title2:'訂購成功',
        order_title3:'已失效',
        order_title4:'支付中',
        bonus_deduction:'獎金扣減',
        payment_method:'付款方式',
        successfully:'订购成功',
        cancel_tips:'確認取消訂單？',
        salesman:'销售员'
    },
    lucky:{
        getprize:'恭喜您獲得了',
        valued:"價值",
        user:"用戶",
        amount:"金額",
        time:'時間'
    },
    cart: {
        items:'项目',
        checkOut:'結賬',
        masterOrder:'主訂單號',
        goods_count:'商品總數',
        gift_count:'发放奖品数量',
        category_name:"分類名稱",
        merchandise:"商品",
        price:"價格",
        amount:"金額",
        subtotal:"合計",
        for_commercial:"商用",
        for_domestic:"自用",
        total:"總金額",
        checkout:"結算",
        tip:"所有圖片只供參考一切以實物為準",
        spec:"規格",
        min_amount:'最低收費为',
        add_carts:'已加入購物車！',
        remove_carts:"已移除移除購物車！",
        delete_tip:'確認刪除該商品嗎?',
        deleting:'正在刪除',
        limit_tip:"商品限購",
        count_tip:"商品庫存不足",
        cart_tip:'購物車不能爲空',
        min_amount_tip:"不能低於起送金額",
        cart_error:'購物車數據異常，請返回購物車處理',
        back_cart:"返回購物車",
        send_tip:"送貨時間不能爲空",
        Free_Gift:"平臺贈送禮物",
        Free_Prize:"平臺贈送獎勵",
        Order_reduction:'訂單立減',
        quantity:"数量",
        Redeem_prize:"兌換獎品",
        prize:'獎品',
        limit:"限購",
        empty_tip:'購物車中沒有物品！',
        add_on:'添加',
        num_tip:'數字不能爲0',
        delete_order:'是否要刪除補充訂單？',
        delete_order2:'補充訂單下的貨物將修改爲主訂單',
        modified_tip:'補充訂單信息已更改',
        order_date:'訂單日期',
        give_prizes:'发放奖品'
    },
    payment:{
        platform_rewards:'平臺贈送獎勵',
        order_reduction:"訂單立減",
        voucher:"優惠卷",
        discount:"折扣",
        grand_total:"總金額",
        grand_total_in:"商用需支付金額",
        grand_total_out:"自用需支付金額",
        method_of_payment:"付款方式",
        pay_online:"即時在綫付款",
        pay_delivery:"貨到付款",
        deivery_in:"送貨信息",
        deivery_date:"送貨日期",
        delivery_tip:'貨到前',
        delivery_tip2:'致電給我',
        mins:'分鐘',
        for_commercial:"商用",
        for_domestic:"自用",
        tip:'截单时间为晚上十一时',
        tip2:'之后的订单会下一个工作天计算',
        required_payment:"需支付總金額",
        online_total:"在綫支付金額",
        payment:"支付",
        payment_total:'商品總價',
        not_used:'不使用獎金',
        use_all:'使用所有獎金',
        use_part:'使用部分獎金',
        please_enter:'請填寫獎金金額'
    }
}