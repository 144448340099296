<template>
  <div>
    <div class="headerWrapper"> 
      <header class="header"> 
        <header-tab></header-tab>
      </header>
    </div>
    <div class="header-bor container"> 
      <logo-tab></logo-tab>
      <div class="navigation-menu">
        <div class="search-bor" v-show="searchType || isInput">
            <input type="text" placeholder="SEARCH" v-model="input2" @keyup.enter="getSearch"/>
            <el-button type="success" @click="getSearch" style="background: #43BD5C;height: 102%;"><i class="el-icon-search"></i></el-button>
        </div>
        <div class="prize" v-show="prizeType"> {{$t('common.prize_mall')}} </div>
        <div class="nav-title" v-if="!isInput">{{navTitle}}</div>
        <!-- 最爱 -->
        <img class="favourite" @click="favouriteFun" v-if="isFavourite" src="@/assets/images/favourite.png"/>
        <!-- 积分  -->
        <div class="integral" v-show="integralType" @click="jumpIntegral">
            <img src="@/assets/images/integral_icon.png"/>
            <span class="integral-num">{{ parseFloat($store.state.wallet.wallet).toFixed(2) }}</span>
        </div>
        <!-- 购物车 -->
        <div class="cart_c" @click="getCart" v-show="carts">
            <el-badge v-if="$store.state.cart.allGoods != 0" :value="$store.state.cart.allGoods" class="item">
                <img src="@/assets/images/cart.png"/>
            </el-badge>
            <img v-if="$store.state.cart.allGoods == 0" src="@/assets/images/cart.png"/>
            <div class="cart-btn_c">
                <div class="cart-title_c">Cart</div>
                <div class="cart-price_c"><span>£</span>{{ $store.state.cart.goodsAmount }}</div>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script scoped>
export default {
  name: "navigationTab",
  props:{
    "integralType":{
      default:true,
      type:Boolean
    },
    "searchType":{
      default:true,
      type:Boolean
    },
    "prizeType":{
      default:true,
      type:Boolean
    },
    "isInput": {
      type: Boolean, 
      default: false 
    },
    "navTitle": {
      type: String, 
      default: ""
    },
    "carts": {
      type: Boolean, 
      default: false 
    },
    "isFavourite": {
      type: Boolean, 
      default: false 
    }
  },
  data(){
    return{
      input2:''
    }
  },
  created() {
    if(this.$store.state.user.token  && this.$store.state.user.token != '') {
      this.$store.dispatch('getWallet');
      this.getGoodsNumFun()
    }
  },
  methods:{
    favouriteFun(){
      this.$router.push({path:'/personal/index',query:{pageType:"favourite"}})
    },
    getSearch(){
      this.$router.push({path:'/search/index',query:{goodsName:this.input2}});
    },
    jumpIntegral() {
      this.$router.push('/wallet/index')
    },
    getCart() {
      this.$router.push('/cart/index')
    },
    getGoodsNumFun(){
      let that = this;
      let params = {
          "inPaymentType": "ONLINE",
          "outPaymentType": "ONLINE",
          "type": that.orderObject ? 'ADD' : 'NEW'
      }
      that.$store.dispatch('getGoodsNum', params);
    },
  }
}
</script>

<style>
  .favourite{
    width: 124px;
    margin-left: 38px;
    cursor: pointer;
  }
  .nav-title{
    font-size: 32px;
    font-weight: 900;
    color: #333333;
    margin-right:22px;
  }
    .header{
        background-color: #fff;
        top: 0;
        left: 0;
        width: 100%;
        line-height: 140px;
        z-index: 100;
        position: relative;
    }
    .headerWrapper {
        position: fixed;
        width: 100%;
        left: 0;
        top: 0;
        z-index: 1500;
    }
    .header-bor{
        height: 118px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .container{
        margin: 0 auto;
        width: 1186px;
    }
    .navigation-menu{
      display: flex;
      align-items: center;
    }
    .search-bor{
    width: 440px;
    height: 40px;
    line-height: 40px;
    background: #FFFFFF;
    border: 1px solid #43BD5C;
    border-radius: 20px;
    position: relative;
  }
  .search-bor .el-icon-search{
    font-size: 20px;
  }
  .search-bor input{
    width: 100%;
    height: 98%;
    line-height: 38px;
    border-radius: 20px;
    border: 0px;
    background-color: rgba(000, 000, 000, 0);
    outline: none;
    font-size: 16px;
    font-weight: 400;
    text-indent:10px;
    z-index: 100;
    caret-color:auto;
  }
  .search-bor button{
    position: absolute;
    right: 0;
    top: 0;
    width: 80px;
    height: 100%;
    border-radius: 20px;
  }
  .integral{
    height: 43px;
    padding: 0 10px 0 4px;
    display: flex;
    align-items: center;
    background-color: #FED339;
    border-radius: 20px;
    background-size: 100% 100%;
    margin-left: 38px;
    cursor: pointer;
  }
  .integral img{
    width: 36px;
    height: 36px;
  }
  .integral-num{
    display: inline-block;
    margin-left: 10px;
    font-size: 26px;
    font-weight: 900;
    color: #333333;
  }
  .prize {
    height: 24px;
    font-size: 32px;
    font-weight: 900;
    color: #333333;
    line-height: 24px;
  }
  .cart_c{
    display: flex;
    align-items: center;
    line-height: normal;
    margin-left: 36px;
    cursor: pointer;
  }
  .cart-btn_c{
    text-align: left;
    margin-left: 20px;
  }
  .cart-price_c{
    font-size: 26px;
    font-weight: bold;
    color: #333333;
    line-height: 24px;
  }
  .cart-price_c span{
    display: inline-block;
    margin-right: 2px;
    font-size: 18px;
  }
  .cart-title_c{
    font-size: 18px;
    font-weight: 400;
    color: #999999;
  }
  .cart_c img{
    width: 41px;
    height: 46px;
  }
</style>