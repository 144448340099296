<template>
  <div> 
    <el-backtop :bottom="100">
        <div @click.stop="cartFun" class="up-btn carts-btn" v-show="showCart">
            <el-badge v-if="$store.state.cart.allGoods != 0" :value="$store.state.cart.allGoods" class="item">
            <img src="@/assets/images/cart.png"/>
            </el-badge>
            <img v-else src="@/assets/images/cart.png"/>
        </div>
        <div class="up-btn">
            <img src="@/assets/images/up.png"/>
        </div>
    </el-backtop>
  </div>
    
</template>

<script scoped>

export default {
    name:"suspensionTab",
    props:{
      "showCart":{
        type:Boolean,
        default:false
      }
    },
    methods:{
      cartFun(){
        this.$router.push('/cart/index');
      }
    }
}
</script>

<style>
    .carts-btn .el-badge__content{
        top: 24px !important;
    }
  .el-backtop{
    width: auto;
    height: auto;
    display: block;
    box-shadow:0px 0px 0px !important;
    background-color:rgba(0,0,0,0);
  }
  
  .carts-btn{
    margin-bottom: 24px;
  }
  .up-btn img{
    width: 28px;
    height: 28px;
    margin: 22px auto;
    display: block;
  }
  .up-btn{
    width: 72px;
    height: 72px;
    line-height: 72px;
    background: #FFFFFF !important;
    box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.1) !important;
    border-radius: 50%;
    border: 1px solid #ffffff;
  }
  .up-btn:hover{
    background-color: rgba(000, 000, 000, 0);
  }
  .el-backtop:hover{
    background-color: rgba(000, 000, 000, 0);
  }
</style>