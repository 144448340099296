import {getCartprice} from  '@/api/cart';

const cart = {
    state: {
        allGoods:0,//购物车总数量
        goodsAmount:0//购物车总金额
    },
    mutations: {
        SET_GOODS_NUM: (state, num) => {
            state.allGoods = num;
        },
        SET_GOODS_AMOUNT: (state, amount) => {
            state.goodsAmount = amount;
        }
    },
    actions: {
        getGoodsNum({ commit }, params){
            return new Promise((resolve, reject) => {
                getCartprice(params).then(res => {
                    if(res.code == 200 || res.code == 10000){
                        let dataNum = 0;
                        res.data.userCartCategory.map(item => {
                            item.userCartGoodsDetail.map((key,val) => {
                                dataNum += key.inNum;
                                dataNum += key.outNum;
                            })
                        });
                        commit('SET_GOODS_NUM', dataNum);
                        commit('SET_GOODS_AMOUNT', parseFloat(res.data.totalGoodsAmount).toFixed(2));
                    }
                    resolve(res);
                });
            });
        }
    }
}


export default cart