const categories = {
    state: {
        categoriesList:[],//一级分类
    },
    mutations: {
        SET_CATEGORIES_LIST: (state, num) => {
            state.categoriesList = num;
        }
    },
    actions: {
        
    }
}


export default categories