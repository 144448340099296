<template>
  <div class="header-logo" @click="jumpHome">
    <img src="@/assets/logo.png"/>
    <div class="header-title">Suppally</div>
</div>
</template>

<script>
export default {
    name: "logoTab",
    methods:{
        jumpHome(){
            this.$router.push('/')
        }
    }
}
</script>

<style>
    .header-logo{
        display: flex;
        align-items: center;
        font-size: 32px;
        font-weight: 900;
        color: #333333;
        cursor: pointer;
    }
    .header-title{
        margin-left: 15px;
    }
    .header-logo img{
        /* width: 68px; */
        height: 68px;
    }
</style>