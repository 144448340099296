<template>
  <div class="footer">
    <div>
      <div class="logo_bottom" @click="jumpHome">
        <img class="footer-logo" src="@/assets/logo_bottom.png"/>
        <div>Suppally</div>
      </div>
      
      <div class="contact">
        <img src="@/assets/images/footer_phone.png"/>
        <span>+44 1908 665 883</span>
      </div>
      <div class="contact">
        <img src="@/assets/images/footer_mail.png"/>
        <span>david.ngan@pacificfoods.direct</span>
      </div>
    </div>
    <div class="download-btn">
      <div class="download-app">
        <div>Download the APP</div>
        <img class="apple click-sty" @click="iosLink" @mouseover="iosMouseOver" src="@/assets/images/apple.png"/>
        <img class="google click-sty" @click="androidLick" @mouseover="googleMouseOver" src="@/assets/images/Google.png"/>
      </div>
      <img class="code" :src="linkUrl"/>
    </div>
  </div>
</template>
  
<script>
    export default {
    name: "footerTab",
    data() {
        return {
          linkUrl: require('@/assets/images/ios.png')
        };
    },
    watch: {},
    methods: {
      iosLink(){
        window.open("https://apps.apple.com/cn/app/id1663875066", "_blank");
      },
      iosMouseOver(){
        this.linkUrl = require('@/assets/images/ios.png');
      },
      googleMouseOver(){
        this.linkUrl = require('@/assets/images/android.png');
      },
      androidLick(){
        window.open("https://play.google.com/store/apps/details?id=com.xdwl.suppally", "_blank");
      },
      jumpHome(){
          this.$router.push('/')
      }
    },
    };
</script>
<style scoped>
.click-sty{
  cursor: pointer;
}
.logo_bottom{
  display: flex;
  /* justify-content: center; */
  align-items: center;
  cursor: pointer;
}
.logo_bottom div{
  font-size: 36px;
  margin-left: 20px;
}
.footer{
  background-color: #474A4B;
  height: 280px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  background-image: url("@/assets/images/company.png");
  background-size: 100% 100%;
}
.footer-logo{
  /* width: 348px; */
  height: 89px;
  margin-bottom: 9px;
  margin-left: 6px;
}
.contact{
  display: flex;
  align-items: center;
  margin-top: 16px;
  margin-left: 14px;
}
.contact img{
  width: 26px;
  height: 26px;
  margin-right: 16px;
}
.download-btn{
  display: flex;
  text-align: left;
  align-items: flex-end;
  margin-left: 196px;
}
.download-btn .code{
  width: 136px;
  height: 136px;
  /* border: 4px solid #43BD5C; */
}
.download-app {
  margin-right: 36px;
}
.download-app div{
  margin-bottom: 11px;
  font-size: 24px;
  font-weight: 400;
  color: #FFFFFF;
}
.download-app img{
  width: 219px;
  height: 65px;
  display: block;
  margin-top: 9px;
}
</style>
  