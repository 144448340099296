import request from '@/utils/request'

// 查询购物车金額
export function cartPrice(data) {
    return request({
      url: 'app/cart/calculateAmountByNormal',
      method: 'post',
      data: data
    })
}
//訂單
export function goodsOrder(data) {
  return request({
    url: '/app/mallorder/createOrder',
    method: 'post',
    data: data
  })
}

//訂單
export function getMinCharge(data) {
  return request({
    url: '/app/mallconfig/getMinCharge',
    method: 'post',
    data:data
  })
}

// 查限购数据
export function getLimit(data) {
  return request({
    url: '/app/cart/getGoodsBatchRetain',
    method: 'post',
    data:data
  })
}

// 向购物车某个商品增加或减少数量
export function changeCartnum(data) {
  return request({
    url: '/app/cart/modifyMallCartByStep',
    method: 'post',
    data:data
  })
}

// 设置购物车数量(多个)
export function modifyCartnum(data) {
  return request({
    url: '/app/cart/modifyMallCartBySingleNum',
    method: 'post',
    data:data
  })
}

// 获得购物车列表和相关价格
export function getCartprice(data) {
  return request({
    url: '/app/cart/selectMallCart',
    method: 'post',
    data:data
  })
}

// 刪除购物车
export function deletedCart(data) {
  return request({
    url: '/app/cart/removeMallCartUserGoods',
    method: 'post',
    data:data
  })
}

// 設置購物車數量，在inNum= 1 ,outNum=0 或者 inNum= 0 ,outNum=1時調用
export function changeCartlimit(data) {
  return request({
    url: '/app/cart/modifyMallCartByDoubleNum',
    method: 'post',
    data:data
  })
}

//待支付
export function payForOrder(data) {
  return request({
    url: '/app/mallorder/payForOrder',
    method: 'post',
    data:data
  })
}


//待支付查询
export function getOrderInfo(data) {
  return request({
    url: '/app/mallorder/getOrderInfo',
    method: 'post',
    data:data
  })
}

//设置购物车数量(in and out)但是只能减少并没有校验(1个)
export function ReduceNum(data) {
  return request({
    url: '/app/cart/modifyMallCartByDecreaseWithoutCheck',
    method: 'post',
    data:data
  })
}

//获取用户配送日期
export function getDeliveryTime() {
  return request({
    url: '/app/mallorder/deliveryTime',
    method: 'get'
  })
}