import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

const i18n = {
    locale : localStorage.getItem('lang') ? localStorage.getItem('lang') : localStorage.setItem('lang','en'),
    messages:{
        zh: require('./zh'),
        en: require('./en')
    },
    silentFallbackWarn: true
}
export default i18n;