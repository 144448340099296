module.exports = {
    router:{
       home:'home' 
    },
    common:{
        status:"Status",
        confirm:"Confirm",
        cancel:'Cancel',
        name:'Name',
        detail:"Detail",
        deleteTip:"Are you sure to delete this order?",
        tip:"Tip",
        success:"Added to favourite",
        error:"Operation failed",
        close:'Close',
        delete:"Delete",
        prize_mall:"Prize Mall",
        Submit:"Submit",
        out_stock:"out of stock",
        in_stock:"In Stock",
        stock:"Stock",
        sale:"SALE",
        login_set:"SIGN IN TO VIEW MORE OFFERS",
        login:"LOG IN",
        home:"Go to the home page",
        home_url1:'HOME',
        home_url2:'MY ORDER',
        home_url3:'MY ACCOUNT',
        home_url4:'PRIZE MALL',
        searchFont:'Search what you looking for？',
        searchTip:'Please enter what you want to find',
        categories:'Categories',
        link:'Userful Links',
        content:'Content Us',
        download:'Download App',
        is_out:'Are you sure to log out？'
    },
    login:{
        success:'Modified successfully',
        tip:'Click the button to log in again',
        phone:'Mobile Phone',
        password:'Password',
        login:'Login',
        sign_out:'Sign out'
    },
    home:{
        add:'ADD TO BASKET',
        stock:'Items Left in Stock!',
        end:'END',
        hot_sale:'BEST SELLING PRODUCTS',
        offer:'OFFER FOR YOU',
        view_all:'View all',
        contect_us:'Contect Us',
        call_us:'Call Us',
        categories:'ALL CATEGORIES',
        notice:'NOTICE !',
        search_none:'No items found',
        wish_list:'Wish List',
        my_Order:'My Order',
        my_Account:'My Account',
        about_Us:'About Us',
        reture_Policy:'Reture Policy',
        terms_of_Service:'Terms of Service',
        Email:'Email',
        Tel:'Tel',
        no_goods:'be temporarily out of stock'
    },
    personal:{
        personal:'Personal',
        favourites:"Favourites",
        changepass:"Change Password",
        more:'More',
        small_more:'more',
        wallet_balance:'POINTS BALANCE',
        redeem:"Redeem",
        orderNum:'Order Number',
        orderDate:'Order Date',
        shippingDate:'Shipping Date',
        operation:'Option',
        myOrder:'My Order',
        oldpass:'Old password',
        newpass:'New Password',
        confirmpass:'Confirm Password',
        newpass_tip:'Please enter your new password',
        oldpass_tip:"Please enter your old password",
        confirmpss_tip:"Please enter your confirmation password",
        pas_tip:"Please input a password",
        num_tip:"Please enter the account number",
        pas_success:"Password changed successfully",
        favorite_canceled:"Removed from favourite",
        view_details:"VIEW DETAILS"
    },
    wallet :{
        Variation:'Points',
        ORDER_REFUND:'ORDER REFUND',
        THE_PURCHASE_OF_GOODS:'THE PURCHASE OF GOODS',
        ACTIVITY_BOUNDS:'ACTIVITY BOUNDS',
        ORDER_BONUS:'POINTS GAINED',
        CUT_BONUS:'CUT BONUS',
        REDEEM_PRIZE:'REDEEM PRIZE',
        order:'Order',
        history:'History',
        empty_tip:'No detailed records yet',
        History:'History'
    },
    prize:{
        wallet:'Wallet',
        balance:'Balance',
        redeemSuccess:'Redeem succeeded',
        redeemWarning:"Redeem failed",
        des:"Prizes cannot be changed, deleted or returned once redeemed The Company reserves the right of final interpretation",
        exchange_clause:"Exchange clause",
        tc1:'The prizes are subjected to availability. Pacific Foods reserves the right to hold void, cancel, suspend, substitute, or amend the prizes whenever it desires without giving notice.',
        tc2:"The decision of Pacific Foods regarding any aspect of the Lucky Wheel will be final and binding. No correspondence will be entered into about it."
    },
    order:{
        status0:'Pay',
        status1:"Complete",
        status2:"failed",
        status3:'Customer canceled',
        status4:"Invalid",
        status5:"Refunded",
        status6:'In payment',
        status7:"Deleted",
        ADD_ON:"ADD ON",
        ADD_ON2:"ADD-ON",
        review:"REVIEW",
        cancel:"CANCEL",
        lottery:"LOTTERY",
        deleted:"DELETED",
        paymenting:"In payment...",
        review_order:"Review Order",
        review_tip:'Please rate your salesman',
        rate:'Rate',
        remark:"Remark",
        order_detail:"Order Detail",
        free_prize:"Prize",
        goods_total:'Total of items',
        top_up:"Top Up",
        master_order:"MAIN ORDER",
        supplementary_order:"SUPPLEMENTARY ORDER",
        commodity_information:"ORDER INFORMATION",
        batch_number:'BATCH NUMBER',
        star_tip:"The score cannot be less than 1 star",
        suborder_number:"SubOrder Number",
        creat_time:"Order date",
        order_title1:'Pending Payment',
        order_title2:'Order successfully',
        order_title3:'Expired',
        order_title4:'In payment',
        bonus_deduction:'Bonus deduction',
        payment_method:'Payment method',
        successfully:'Order successfully',
        cancel_tips:'Are you sure to cancel the order?',
        salesman:'salesman'
    },
    lucky:{
        getprize:'You won',
        valued:"Value",
        user:"User",
        amount:"Amount",
        time:'Time'
    },
    cart: {
        items:'Items',
        checkOut:'Check Out',
        masterOrder:'Master order Number',
        goods_count:'Shopping Cart',
        gift_count:'Give out prizes Quantity',
        category_name:"Category",
        merchandise:"Product",
        price:"Price",
        amount:"Amount",
        subtotal:"Subtotal",
        for_commercial:"For Commercial",
        for_domestic:"For Domestic",
        total:"Total",
        checkout:"Checkout",
        tip:"All pictures are for reference only. Actual product may vary",
        tips_title:"Prompt",
        spec:"Unit",
        min_amount:'The minimum charge is',
        add_carts:'Added to shopping cart!',
        remove_carts:"Cart removed!",
        delete_tip:'Are you sure to delete this item?',
        deleting:'Deleting',
        limit_tip:"Restricted purchase of goods",
        count_tip:"Insufficient inventory of goods",
        cart_tip:'Shopping cart cannot be empty',
        min_amount_tip:"Cannot be less than the minimum delivery amount",
        cart_error:'The shopping cart data is abnormal. Please return to the shopping cart for processing',
        back_cart:"Return to shopping cart",
        send_tip:"Delivery time cannot be empty",
        Free_Gift:"Free Gift",
        Free_Prize:"Free Prize",
        Order_reduction:'Order reduction',
        quantity:"Quantity",
        Redeem_prize:"Redeem Prize",
        prize:'Prize',
        limit:"Limit",
        empty_tip:'There is no item in the cart!',
        add_on:'Add-on',
        num_tip:'The number cannot be 0',
        delete_order:'Do you want to delete the supplementary order?',
        delete_order2:'The goods under the supplementary order will be modified to the main order',
        modified_tip:'Supplementary order information has been changed',
        order_date:'Order Date',
        give_prizes:'Give out prizes'
    },
    payment:{
        platform_rewards:'Platform Rewards',
        order_reduction:"Order Reduction",
        voucher:"Voucher",
        discount:"Discount",
        grand_total:"Grand Total",
        grand_total_in:"Grand Total",
        grand_total_out:"Grand Total",
        method_of_payment:"Method of Payment",
        pay_online:"Pay Online",
        pay_delivery:"pay On Delivery",
        deivery_in:"DELIVERY INSTRUCTION",
        deivery_date:"DELIVERY DATE",
        delivery_tip:'Please call before',
        delivery_tip2:'Call me',
        mins:'mins',
        for_commercial:"FOR COMMERCIAL",
        for_domestic:"FOR DOMESTIC",
        tip:'The cut-off time is 11pm',
        tip2:'orders will be counted on the following working day',
        required_payment:"Required Payment",
        online_total:"Online Total",
        payment:"Payment",
        payment_total:'total',
        not_used:'Not using the points',
        use_all:'Pay by points',
        use_part:'Pay by points (custom amount)',
        please_enter:'Please fill in the amount of the bonus'
    }
}