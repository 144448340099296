<template>
  <el-dialog :visible.sync="promptShow" width="30%" :show-close="false" :close-on-click-modal="false"> 
    <div class="notice_top"> 
        <div class="prompt-title">PROMPT</div>
        <div class="prompt-data">There's a discount if you buy more than one</div>
        <div class="confirm_btn" @click="promptHide()">{{$t('common.confirm')}}</div>
    </div>
  </el-dialog>
</template>

<script>
export default {
    props: {
        promptShow:{
            default:false,
            type:Boolean
        },
    },
    data(){
        return{
            
        }
    },
    methods:{
        promptHide(){
            this.$emit('promptPop',false);
        }
    }
}
</script>

<style scoped>
.notice_top{
  border-radius: 30px;
  border:1px solid #FFFFFF;
  background-color: #FFFFFF;
  padding: 40px;
}
.prompt-title{
    font-size: 28px;
    font-family: Gotham-Bold;
    font-weight: bold;
    color: #000000;
}
.prompt-data{
    font-size: 22px;
    font-family: Gotham-Bold;
    font-weight: bold;
    color: #333333;
    margin-top: 30px;
}
.confirm_btn{
  width: 300px;
  height: 60px;
  line-height: 60px;
  background: #FF8500;
  border-radius: 15px;
  font-size: 22px;
  font-family: Gotham-Bold, Gotham;
  font-weight: bold;
  color: #FFFFFF;
  margin: 35px auto;
  margin-bottom: 10px;
  cursor: pointer;
}
</style>