import  Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

Vue.use(VueRouter)

//防止路由重复加载报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

/* 如果路由中有meta的requireAuth，且为true，就进行登录验证，否则不进行登录验证*/
var router = new VueRouter({
    routes:[
        {
            path:'/',
            name:'home',
            component:() => import('../view/home/index.vue'),
            meta:{
                requireAuth:false
            }
        },
        {
            path:'/login',
            name:'login',
            component:() => import('../view/Login/LoginPage.vue'),
            meta:{
                requireAuth:false
            }
        },
        {
            path:'/cart/index',
            name:'cart',
            component:() => import('../view/cart/index.vue'),
            meta:{
                requireAuth:true
            }
        },
        {
            path:'/wallet/index',
            name:'wallet',
            component:() => import('../view/Wallet/walletIndex.vue'),
            meta:{
                requireAuth:true
            }
        },
        {
            path:'/personal/index',
            name:'Personal',
            component:() => import('../view/Personal/PersonalIndex.vue'),
            meta:{
                requireAuth:true
            }
        },
        {
            path:'/prize/index',
            name:'Prize',
            component:() => import('../view/Prize/PrizeIndex.vue'),
            meta:{
                requireAuth:true
            }
        },
        {
            path:'/search/index',
            name:'search',
            component:() => import('../view/Search/searchIndex.vue'),
            meta:{
                requireAuth:true
            }
        },
        {
            path:'/lucky/index',
            name:'Lucky',
            component:() => import('../view/LuckyWheel/LuckwheelIndex.vue'),
            meta:{
                requireAuth:true
            }
        },
        {
            path:'/payment/index',
            name:'payment',
            component:() => import('../view/payment/index.vue'),
            meta:{
                requireAuth:true
            }
        },
        {
            path:'/order/index',
            name:'order',
            component:() => import('../view/order/index.vue'),
            meta:{
                requireAuth:true
            }
        },
        {
            path:'/order/detail',
            name:'orderDetail',
            component:() => import('../view/order/detail.vue'),
            meta:{
                requireAuth:true
            }
        },
        {
            path:'/home/list',
            name:'list',
            component:() => import('../view/home/list.vue'),
            meta:{
                requireAuth:true
            }
        },
        {
            path:'/payment/success',
            name:'success',
            component:() => import('../view/payment/success.vue'),
            meta:{
                requireAuth:true
            }
        }
    ]
})

// 挂载路由导航守卫
router.beforeEach((to, from, next) => {
    // to 将要访问的路径
    // from 代表从哪个路径跳转而来
    // next 是一个函数， 表示放行   ①next()放行   ②next('/login')强制跳转
    // 获取token的值
    const token = store.state.user.token;
 
    // // 获取存储token的开始时间
    // const tokenStartTime = window.sessionStorage.getItem('tokenStartTime');
    // // 后台给出的token有效时间，一个星期 单位 是秒
    // // 我们自己定义6天过期，让用户重新登录一下， 用户总不可能在一个页面挂机一天吧
    // const timeOver = 6 * 24 * 3600 * 1000;
    // // 当前时间
    // let date = new Date().getTime();
    // // 如果大于说明是token过期了
    // if (date - tokenStartTime > timeOver) {
    //     token = null;
    // }
    // 如果token过期了
    
    if (to.meta.requireAuth) {
        if (token) {
            to.path == '/login' ? next('/') : next()
        } else {
            next('/login')
        }
    } else {
            next()
    }

});

export default router;