import { getWallet } from '@/api/login'

const wallet = {
  state: {
    wallet:0,
    topHeight:0
  },

  mutations: {
    SET_WALLET: (state, wallet) => {
      state.wallet = wallet;
    },
    SET_HEIGHT: (state, topHeight) => {
      state.topHeight = topHeight;
    },
  },

  actions: {
    getWallet({ commit }) {
      return new Promise((resolve, reject) => {
        getWallet().then(res => { 
          commit('SET_WALLET', res.data.amount)
          sessionStorage.setItem('walletNumber',res.data.amount)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    }
  }
}

export default wallet
