import Vue from 'vue'
import App from './App.vue'
import axios from 'axios'
import router from './router/router'
import i18n from  './lang/index'
import store from './store/index'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import locale from 'element-ui/lib/locale/lang/en';
import VueLuckyCanvas from '@lucky-canvas/vue'
import ElTableInfiniteScroll from "el-table-infinite-scroll";
import loadmore from '@/utils/loadmore'// 表格滚动加载
import "animate.css";

import { parseTime , formatWeek } from "./utils/tools"

loadmore.install(Vue)

Vue.use(VueLuckyCanvas)

import topIndex from '@/components/topIndex/index.vue';
Vue.component('topIndex', topIndex)

import footerTab from '@/components/footerTab/index.vue';
Vue.component('footerTab', footerTab)

import footerIndex from '@/components/footerTab/newIndex.vue';
Vue.component('footerIndex', footerIndex)

import headerTab from '@/components/headerTab/index.vue';
Vue.component('headerTab', headerTab)

import logoTab from '@/components/logoTab/index.vue';
Vue.component('logoTab', logoTab)

import navigationTab from '@/components/navigationTab/index.vue';
Vue.component('navigationTab', navigationTab)

import suspensionTab from '@/components/suspensionTab/index.vue';
Vue.component('suspensionTab', suspensionTab)

import prompt from '@/components/goodsList/prompt.vue';
Vue.component('prompt', prompt)

Vue.directive("el-table-infinite-scroll", ElTableInfiniteScroll);

Vue.use(ElementUI, {locale});

Vue.config.productionTip = false
Vue.prototype.$http = axios

Vue.prototype.parseTime = parseTime
Vue.prototype.formatWeek = formatWeek

Vue.prototype.openLoading = function(font) {
  const loading = this.$loading({           // 声明一个loading对象
    lock: true,                             // 是否锁屏
    text: font,                         // 加载动画的文字
    spinner: 'el-icon-loading',             // 引入的loading图标
    background: 'rgba(fff, fff, fff, 0.3)',       // 背景颜色
    fullscreen: false,
    customClass: 'loadingclass'             // **遮罩层新增类名，如果需要修改loading的样式**
  })
  // setTimeout(function () {                  // 设定定时器，超时5S后自动关闭遮罩层，避免请求失败时，遮罩层一直存在的问题
  //   loading.close();                        // 关闭遮罩层
  // },5000)
  return loading;
}

Vue.prototype.getSecondTime = function(time) {
  // 转换为式分秒
  let h = parseInt(time / 60 / 60 % 24)
  h = h < 10 ? '0' + h : h
  let m = parseInt(time / 60 % 60)
   m = m < 10 ? '0' + m : m
  let s = parseInt(time % 60)
   s = s < 10 ? '0' + s : s
  // 作为返回值返回
  return [h, m, s]
}

// 处理页面跳转后不在顶部问题
router.afterEach((to, from, next) => {
  window.scrollTo(0, 0)
})

new Vue({
  router,
  i18n,
  store,
  render: h => h(App),
}).$mount('#app')
