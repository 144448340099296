<template>
  <div class="foot-index">
    <div class="foot-bor">
        <div class="foot-item item1"> 
            <img src="@/assets/images/suppally.png" class="logo"/>
            <div class="foot-font">6 Davy Avenue,  Knowlhill,</div>
            <div class="foot-font">Milton Keynes,Buckinghamshire,</div>
            <div class="foot-font">MK5 8HJ</div>
        </div>
        <div class="foot-item item2"> 
            <div class="foot-title">{{ $t('common.categories') }}</div>
            <div class="foot-font pointers" @click="choseTab(item)" v-for="(item, index) in dataList" :key="index">{{lang == 'zh'? item.label :item.labelEn }}</div>
        </div>
        <div class="foot-item item3"> 
            <div class="foot-title">{{ $t('common.link') }}</div>
            <div class="foot-font pointers">{{ $t('home.wish_list') }}</div>
            <div class="foot-font pointers" @click="getPage('/order/index')">{{ $t('home.my_Order') }}</div>
            <div class="foot-font pointers" @click="getPage('/personal/index')">{{ $t('home.my_Account') }}</div>
            <div class="foot-font pointers">{{ $t('home.about_Us') }}</div>
            <div class="foot-font pointers">{{ $t('home.reture_Policy') }}</div>
            <div class="foot-font pointers">{{ $t('home.terms_of_Service') }}</div>
        </div>
        <div class="foot-item item4"> 
            <div class="foot-title">{{ $t('common.content') }}</div>
            <div class="foot-font">{{ $t('home.Email') }}: cs@suppally.com</div>
            <div class="foot-font">{{ $t('home.Tel') }}:  +44 1908 665 883</div>
        </div>
        <div class="foot-item item5">
            <div class="foot-title">{{ $t('common.download') }}</div>
            <img src="@/assets/images/app-store.png" @click="iosLink" class="download"/>
            <img src="@/assets/images/google-play.png" @click="androidLick" class="download"/>
        </div>
    </div>
    <!-- <div class="reserved">2023 Suppally - All Rights Reserved</div> -->
    <img class="foot_left_img" src="@/assets/images/foot/left.png"/>
    <img class="foot_right1_img" src="@/assets/images/foot/right1.png"/>
    <img class="foot_right2_img" src="@/assets/images/foot/right2.png"/>
    <img class="foot_right3_img" src="@/assets/images/foot/right3.png"/>
  </div>
</template>

<script>
import {mapState} from 'vuex';
export default {
    data(){
        return{
            lang:localStorage.getItem('lang') || 'en',
            dataList:[]
        }
    },
    computed:mapState(['categories']),
    watch: {
        'categories.categoriesList':{
            handler(newValue, oldValue) {
                this.dataList = newValue;
            },
            deep:true
        }
    },
    mounted(){
        this.dataList = this.$store.state.categories.categoriesList;
    },
    methods:{
        choseTab(item) { //点击tab 查询二级分类
            sessionStorage.setItem("secondaryClass",JSON.stringify(item));
            // this.tabNum = item.id;
            this.$store.commit('HOME_TAB_INDEX', {tabNum:item.id});
            if(this.$route.name == 'list'){
                sessionStorage.setItem("firstGetList",'2');
                this.$store.commit('HOME_LIST', {item:item});
            }else{
                sessionStorage.setItem("firstGetList",'1');
                this.$router.push({path:'/home/list',query:{item:item}});
            }
        },
        getPage(page){
            this.$router.push(page)
        },
        iosLink(){
            window.open("https://apps.apple.com/cn/app/id1663875066", "_blank");
        },
        androidLick(){
            window.open("https://play.google.com/store/apps/details?id=com.xdwl.suppally", "_blank");
        },
        jumpHome(){
            this.$router.push('/')
        }
    }
}
</script>

<style>
.foot_left_img{
    position: absolute;
    bottom: -20px;
    left: -20px;
    width: 210px;
    height: 137px;
}
.foot_right1_img{
    position: absolute;
    top: -44px;
    right: -20px;
}
.foot_right2_img{
    position: absolute;
    bottom: -38px;
    right: -26px;
}
.foot_right3_img{
    position: absolute;
    bottom: -58px;
    right: 320px;
}
.reserved{
    position: absolute;
    left: 6%;
    bottom: 5%;
    font-size: 14px;
    font-family: Gotham-Book;
    font-weight: normal;
    color: #565B71;
}
.pointers{
    cursor: pointer;
}
.foot-bor{
    display: flex;
    align-items: flex-start;
    width: 92%;
    margin: 0 auto;
    /* padding-top: 100px; */
}
.foot-bor .item1{
    width: 23%;
}
.foot-bor .item5{
    width: 14%;
}
.download{
    display: block;
    margin-bottom: 20px;
    cursor: pointer;
}
.foot-font{
    font-size: 16px;
    font-family: Gotham-Book;
    font-weight: normal;
    color: #565B71;
    margin: 10px 0;
}
.foot-index{
    width: 94%;
    margin: 0 auto;
    margin-bottom: 40px;
    margin-top: 60px;
    background-size: 100% 100%;
    position: relative;
    background-color: #FFF4EA;
    border-radius: 20px;
    padding: 60px 0;
    position: relative;
    /* height: 650px; */
    /* background: url('@/assets/images/foot.png') no-repeat; */
    /* justify-content: space-between; */
}
.foot-title{
    font-size: 25px;
    font-family: Gotham-Bold;
    font-weight: bold;
    color: #040404;
    margin-bottom: 20px;
}
.foot-item{
    width: 21%;
    text-align: left;
}
.foot-item .logo{
    width: 40%;
    height: auto;
    margin-bottom: 20px;
}
</style>