<template>
  <div id="app">
    <router-view v-if="isRouterAlive"/>
  </div>
</template>

<script>
import CalibrationOfBrowser from "./utils/CalibrationOfBrowser";
export default {
  name: 'App',
  components: {} ,
  data() {
    return {
      isRouterAlive:true
    }
  },
  watch: {
    '$i18n.locale' (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.reload()
      }
    },
  },
  created() {
    sessionStorage.setItem('isObject','1');
    if (sessionStorage.getItem("userLevel") ) {
      this.$store.state.user.userLevel = JSON.parse(sessionStorage.getItem("userLevel"))
    }
    window.addEventListener("beforeunload",()=>{
      sessionStorage.setItem("userLevel",JSON.stringify(this.$store.state.user.userLevel))
    })
    new CalibrationOfBrowser().init();
  },
  methods:  {
    reload () {    
        this.isRouterAlive = false;
        this.$nextTick(() => {
              this.isRouterAlive = true;
        })
    }
  }
}
</script>

<style>
.el-badge__content, .el-progress.is-exception .el-progress-bar__inner{
  background-color: #43BD5C !important;
}
#app {
  /* font-family: Roboto; */
  font-family: 'Gotham-Medium';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* margin-top: 50px; */
  min-width: 1200px;
}
body,html{
  padding: 0px;
  margin: 0px;
}
.el-loading-mask{
  top: 0px !important;
}

@font-face {
    font-family: 'Roboto';
    src: url('./assets/font/Roboto-Bold.ttf');
}
@font-face {
    font-family: 'Gotham-Medium';
    src: url('./assets/font/Gotham-Medium.otf');
}
@font-face {
    font-family: 'Gotham-Bold';
    src: url('./assets/font/Gotham-Bold.otf');
}
@font-face {
    font-family: 'Gotham-BoldItalic';
    src: url('./assets/font/Gotham-BoldItalic.otf');
}
@font-face {
    font-family: 'Gotham-Book';
    src: url('./assets/font/Gotham-Book.otf');
}
/* 隐藏鼠标点击这些元素时出现的光标 */
/* div, span, p {
    caret-color: transparent;
} */
.el-breadcrumb__inner a, .el-breadcrumb__inner.is-link{
  color: #FF7D0A !important;
}
.el-breadcrumb__item:last-child .el-breadcrumb__inner, .el-breadcrumb__item:last-child .el-breadcrumb__inner a, .el-breadcrumb__item:last-child .el-breadcrumb__inner a:hover, .el-breadcrumb__item:last-child .el-breadcrumb__inner:hover{
  color: #606266 !important;
}
</style>
