const checkList = {
  state: {
    check_list:{},
    tab_index:{}
  },
  mutations: {
    HOME_LIST: (state, data) => {
      state.check_list = data;
    },
    HOME_TAB_INDEX: (state, data) => {
      state.tab_index = data;
    },
  },
  actions: {}
}

export default checkList
