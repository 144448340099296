import request from '@/utils/request'


// 查询分类列表
export function categoryList(data) {
  return request({
    url: '/app/goods/classification',
    method: 'post',
    data: data
  })
}

// 查询子分类列表
export function subclassList(data) {
  return request({
    url: '/app/goods/list',
    method: 'post',
    data: data
  })
}

// 查询限购
export function getRemain(data) {
  return request({
    url: '/app/goods/getRemain',
    method: 'post',
    data: data
  })
}

export function getList(data) {
  return request({
    url: '/app/goods/category',
    method: 'post',
    data: data
  })
}
//hot
export function getHotList(data) {
  return request({
    url: '/app/goods/listHot',
    method: 'post',
    data: data
  })
}
//获取特惠商品倒计时时间
export function getHotDate(data) {
  return request({
    url: '/app/set/hot/get',
    method: 'get',
    params: data
  })
}

//获取特惠商品倒计时时间
export function getRemind(data) {
  return request({
    url: '/app/set/remind/get',
    method: 'get',
    params: data
  })
}

//首页热销商品列表
export function getHotSaleList(data) {
  return request({
    url: '/app/goods/hot/list',
    method: 'get',
    params: data
  })
}