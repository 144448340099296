import { login, logout } from '@/api/login'
import { getToken, setToken, removeToken } from '@/utils/auth'

const user = {
  state: {
    token: getToken(),
    userInfo:{},
    userLevel:'0'
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token;
    },
    SET_USER_INFO:(state, userInfo) => {
      state.userInfo = userInfo;
    },
    SET_USER_LEVEL: (state, level) => {
      state.userLevel = level
    },
  },

  actions: {
    // 登录
    Login({ commit }, userInfo) {
      const username = userInfo.username.trim()
      const password = userInfo.password
      return new Promise((resolve, reject) => {
        login(username, password).then(res => {
          let userData = {
            phonenumber: res.data.phonenumber,
            nickName:res.data.nickName
          }
          sessionStorage.setItem('cart',[])
          sessionStorage.setItem('orderObject',null)
          sessionStorage.setItem('userData',null)
          sessionStorage.setItem('imgType',0)
          sessionStorage.setItem('path', 'Hot')
          sessionStorage.setItem('userData',JSON.stringify(userData))
          setToken(res.data.token)
          commit('SET_TOKEN', res.data.token)
          commit('SET_USER_INFO',JSON.stringify(res.data))
          commit('SET_USER_LEVEL',res.data.userLevel)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    
    // 退出系统
    LogOut({ commit, state }) {
      return new Promise((resolve, reject) => {
        logout(state.token).then(() => {
          sessionStorage.setItem('cart',[])
          sessionStorage.setItem('orderObject',null)
          sessionStorage.setItem('userData',null)
          sessionStorage.setItem('imgType',0)
          sessionStorage.setItem('path', 'Hot')
          sessionStorage.setItem('userLevel', '0')
          sessionStorage.removeItem('server_time')
          sessionStorage.removeItem('globaltip')
          commit('SET_TOKEN', '');
          commit('SET_USER_INFO','');
          commit('SET_USER_LEVEL','0');
          removeToken()
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 前端 登出
    FedLogOut({ commit }) {
      return new Promise(resolve => {
        sessionStorage.setItem('cart',[])
        sessionStorage.setItem('orderObject',null)
        sessionStorage.setItem('userData',null)
        sessionStorage.setItem('imgType',0)
        sessionStorage.setItem('path', 'Hot')
        sessionStorage.setItem('userLevel', '0')
        sessionStorage.removeItem('server_time')
        sessionStorage.removeItem('globaltip')
        commit('SET_TOKEN', '')
        commit('SET_USER_INFO','')
        commit('SET_USER_LEVEL','0')
        removeToken()
        resolve()
      })
    }
  }
}

export default user
