import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user.js'
import wallet from './modules/wallet.js'
import cart from './modules/cart.js'
import checkList from './modules/checkList.js'
import categories from './modules/categories.js'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    user:user,
    wallet:wallet,
    cart:cart,
    checkList:checkList,
    categories:categories
  }
})

export default store
