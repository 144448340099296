<template>
    <div class="header">
        <div class="top-info">
            <div class="top-menu container">
                <div class="top-menu-phone">
                    <div class="contact-us" @click="getPage('/')">
                        <div class="contact-us-tips">Contact us</div>
                        <div>+44 1908 665 883</div>
                    </div>
                    <img src="@/assets/images/phone.png"/>
                </div>
                <div class="menu-list"> 
                    <span @click="getPage('/order/index')">My order</span>
                    <span class="division">|</span>
                    <span @click="jumpPersonal"><i class="el-icon-user"></i> <span v-if="userData.nickName" class="user-name">{{ userData.nickName }}</span></span>
                    <span class="sign-out" @click="getSignOut" v-if="userData.nickName">Sign out</span>
                    <span class="sign-out" @click="getLogin" v-if="!userData.nickName">Login</span>
                    <span class="division">|</span>
                    <el-dropdown>
                    <span class="el-dropdown-link">
                        {{localeLable}}<i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item @click.native="clickZh">中文</el-dropdown-item>
                        <el-dropdown-item @click.native="clickEn">English</el-dropdown-item>
                    </el-dropdown-menu>
                    </el-dropdown>
                </div>
            </div>
        </div>
    </div>
  </template>
    
  <script>
      export default {
        name: "headerTab",
        data() {
            return {
                userData:{},
                localeLable:localStorage.getItem('lang') == 'en' ? "English":'中文' || "English"
            };
        },
        created(){
            if(JSON.parse(sessionStorage.getItem('userData'))){
                this.userData = JSON.parse(sessionStorage.getItem('userData'));
            }
        },
        methods: {
            clickZh() {
                this.$i18n.locale = 'zh';
                this.localeLable = '中文';
                localStorage.setItem('lang','zh')
            },
            clickEn() {
                this.$i18n.locale = 'en';
                this.localeLable = 'English';
                localStorage.setItem('lang','en')
            },
            getPage(page){
                this.$router.push(page)
            },
            jumpPersonal() {
                this.$router.push('/personal/index')
            },
            getLogin(){
                this.$router.push('/login')
            },
            getSignOut(){
                var that = this;
                that.$confirm('确认退出登录？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    that.$store.dispatch('LogOut').then(() => {
                        this.$router.go(0);
                    }).catch(error => {
                        // 错误分为 status-请求错误 和 code-账号密码错误
                        that.$message.error(error);
                    });

                }).catch(() => {});
            }
        }
    };
  </script>
  <style scoped>
  .user-name{
    margin-right: 19px;
  }
  .sign-out{
    font-size: 14px;
    font-weight: 300;
    text-decoration: underline;
    color: #43BD5C;
    display: inline-block;
  }
     .container{
        margin: 0 auto;
        width: 1186px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
.top-info{
        width: 100%;
        height: 50px;
        line-height: 50px;
        background: #F7F7F7;
    }
    .top-menu{
        height: 100%;
    }
    .top-menu-phone{
        height: 100%;
        display: flex;
        align-items: center;
    }
    .top-menu-phone img{
        width: 21px;
        margin-top: 4px;
        margin-left: 10px;
    }
    .contact-us{
        white-space: nowrap;
        line-height: 16px;
        text-align: right;
        font-size: 18px;
        font-family: Roboto;
        font-weight: 400;
        color: #666666;
        cursor: pointer;
    }
    .contact-us-tips{
        font-size: 14px;
        font-weight: 300;
        color: #999999;
    }
    .menu-list{
        color: #666666;
        font-size: 14px;
        font-weight: 300;
        cursor: pointer;
    }
    .menu-list .el-icon-user{
        border: 1px solid #666666;
        border-radius: 50%;
        display: inline-block;
        margin-right: 9px;
    }
    .menu-list .division{
        display: inline-block;
        margin: 0 22px;
    }
  </style>
    